import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";

interface IUpdateCashback {
	status: string;
	availableAfterDateTime: string | null;
	availableAfterDay: string;
}

const useDailyPlayback = () => {
	const { open } = useFunrizeModals();
	const { refresh: refreshHomePage } = useHomeData({ immediate: false });
	const { promotionsData, refresh: refreshPromo } = usePromotionsPage({ server: true, immediate: false });

	const dailyCashbackData = computed(
		() => promotionsData.value?.promotions?.find((item) => item?.type === "rankLeagueCashback")?.data
	);

	const weeklyCashbackTime = computed(() => dailyCashbackData.value?.availableAfterDateTime);

	const { durationLeft, duration, reset } = useCountdown({
		timestamp: dayjs(weeklyCashbackTime.value),
		formatToken: "H [h] mm [m]"
	});

	const unwatchTimer = watch(
		() => dailyCashbackData.value?.availableAfterDateTime,
		(value) => {
			if (value) {
				reset(value, "H [h] mm [m]");
			}
		}
	);

	const resetWeeklyCashbackTimer = () => {
		if (dailyCashbackData.value?.availableAfterDateTime) {
			reset(dailyCashbackData.value?.availableAfterDateTime);
		}
	};

	const hasDailyReward = computed(
		() => !!dailyCashbackData.value?.currentReward && dailyCashbackData.value?.currentReward > 0
	);
	const statusActivateReward = computed(
		() => dailyCashbackData.value?.sections?.find((item) => !!item?.weeklyReward && item?.weeklyReward > 0)?.title
	);
	const claimIsActive = computed(() => dailyCashbackData?.value?.status === "available");
	const maxReward = computed(() => dailyCashbackData.value?.maxReward);

	const updateCashback = ({ status, availableAfterDateTime, availableAfterDay }: IUpdateCashback) => {
		const cashbackPromo = promotionsData.value?.promotions.find((item) => item?.type === "rankLeagueCashback");
		if (!cashbackPromo) {
			return;
		}

		if (dailyCashbackData && dailyCashbackData.value) {
			const oldTimer = dailyCashbackData.value.availableAfterDateTime;

			dailyCashbackData.value.availableAfterDay = availableAfterDay;
			dailyCashbackData.value.status = status;
			dailyCashbackData.value.availableAfterDateTime = availableAfterDateTime || weeklyCashbackTime.value;

			if (!!availableAfterDateTime && availableAfterDateTime !== oldTimer) {
				resetWeeklyCashbackTimer();
			}
		}
	};

	const claimWeeklyReward = async () => {
		try {
			await apiClient({
				path: "/rest/cashback/pay",
				method: "post",
				options: {
					onResponse: ({ response }) => {
						const prize = response?._data?.data;

						if (prize) {
							open("LazyOModalDailyPlaybackClaimReward", { prize });
						}
					}
				}
			});

			await refreshPromo();
			await refreshHomePage();
		} catch (e) {
			console.error("Error :>> ", e);
		}
	};

	onUnmounted(() => {
		unwatchTimer();
	});

	return {
		dailyCashbackData,
		durationLeft,
		hasDailyReward,
		claimIsActive,
		weeklyCashbackTime,
		duration,
		statusActivateReward,
		maxReward,
		claimWeeklyReward,
		updateCashback,
		resetWeeklyCashbackTimer
	};
};

export default useDailyPlayback;
